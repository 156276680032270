var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: "800px",
        visible: _vm.visible,
        cancelText: _vm.$t("Cancel"),
        title: _vm.$t("Category setting"),
        "confirm-loading": _vm.loading
      },
      on: {
        cancel: function() {
          _vm.$emit("cancel")
        },
        ok: _vm.updateCodesAdvanced
      }
    },
    [
      _c(
        "a-collapse",
        { attrs: { "default-active-key": "1" } },
        [
          _c(
            "a-collapse-panel",
            { key: "1", attrs: { header: _vm.$t("Advanced Information") } },
            [
              _c(
                "a-card",
                [
                  _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("Attached photo")))]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "0.8rem",
                          "font-weight": "500",
                          "white-space": "pre-wrap"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "(If you set an image, it will be displayed as a thumbnail image of Reading Homepage)"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.$t("Attached photo")) + ":")]),
                  _c("upload-photo", {
                    attrs: {
                      url: _vm.thumbnail.url,
                      "folder-name-s3": _vm.folderNameS3
                    },
                    on: {
                      upload: function(newURL) {
                        return _vm.changeThumbnail({ url: newURL })
                      },
                      delete: function() {
                        return _vm.changeThumbnail({ url: "", alt: "" })
                      }
                    }
                  }),
                  _c("br"),
                  _c("div", [_vm._v(_vm._s(_vm.$t("Alt texts")) + ":")]),
                  _c(
                    "a-form",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "a-form-item",
                        {
                          staticClass: "mb-none",
                          attrs: {
                            "validate-status": _vm.validateThumbnailALT,
                            help:
                              _vm.validateThumbnailALT === "error" &&
                              _vm.$t("Photo alt text is required")
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              disabled: !_vm.thumbnail.url,
                              value: _vm.thumbnail.alt
                            },
                            on: {
                              change: function(ref) {
                                var target = ref.target

                                return _vm.changeThumbnail({
                                  alt: target.value
                                })
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("br"),
              !_vm.hasSpotOrNaviCode
                ? [
                    _c(
                      "a-card",
                      [
                        _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("Attached URL")))
                          ]),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "0.8rem",
                                "font-weight": "500",
                                "white-space": "pre-wrap"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "(If you set up a link, this link will be displayed as「More details」in the Uni-Voice Mobile app.)"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("a-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: _vm.$t("(Optional)") },
                          model: {
                            value: _vm.linkURL,
                            callback: function($$v) {
                              _vm.linkURL = $$v
                            },
                            expression: "linkURL"
                          }
                        })
                      ],
                      1
                    ),
                    _c("br")
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }