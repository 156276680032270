//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UploadPhoto from '@/components/spec/UploadPhoto.vue';

// let id = 0;

export default {
  props: {
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    hasSpotOrNaviCode: { type: Boolean, default: false }
  },

  components: { UploadPhoto },

  data() {
    return {
      // janCodes: [],
      thumbnail: { url: '', alt: '' },
      linkURL: ''
    };
  },

  // beforeCreate() {
  //   this.janCodeForm = this.$form.createForm(this, { name: 'jan-code-form' });
  //   this.janCodeForm.getFieldDecorator('keys', { initialValue: [''], preserve: true });
  // },

  computed: {
    formItemLayoutWithOutLabel() {
      return {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 24, offset: 0 }
        }
      };
    },
    validateThumbnailALT() {
      const { url, alt } = this.thumbnail;
      return url && !alt ? 'error' : 'success';
    },
    folderNameS3() {
      return `${process.env.VUE_APP_S3_CODE_THUMBNAIL}/${this.$route.params.pageId}`;
    }
  },

  methods: {
    changeThumbnail(value) {
      this.thumbnail = { ...this.thumbnail, ...value };
    },
    // addJanCode () {
    //   const { janCodeForm } = this;
    //   const keys = janCodeForm.getFieldValue('keys')
    //   const nextKeys = keys.concat(id++);
    //   janCodeForm.setFieldsValue({
    //     keys: nextKeys,
    //   });
    // },
    // removeJanCode (k) {
    //   const { janCodeForm } = this;
    //   const keys = janCodeForm.getFieldValue('keys')
    //   if (keys.length === 1) { return; }
    //   janCodeForm.setFieldsValue({
    //     keys: keys.filter(key => key !== k)
    //   });
    // },
    submitThumbnailForm() {
      return new Promise((resolve, reject) => {
        if (this.thumbnail?.url && !this.thumbnail?.alt) {
          reject('Fail');
        } else {
          resolve(this.thumbnail);
        }
      });
    },
    // submitJanCodeForm () {
    //   return new Promise((resolve, reject) => {
    //     this.janCodeForm.validateFields((err, values) => {
    //       if (!err) {
    //         const { keys, names } = values;
    //         const data = keys.filter(key => names[key]).map(key => names[key])
    //         resolve(data)
    //       } else {
    //         reject('Fail')
    //       }
    //     });
    //   })
    // },
    async updateCodesAdvanced() {
      try {
        const data = await Promise.all([
          this.submitThumbnailForm()
          // ...(!this.hasSpotOrNaviCode ? [this.submitJanCodeForm()] : []),
        ]);
        this.$emit('ok', {
          thumbnail: data[0],
          // ...(!this.hasSpotOrNaviCode ? { jan_codes: data[1] } : {}),
          ...(!this.hasSpotOrNaviCode ? { linkURL: this.linkURL } : {})
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
};
